<template>
  <div>
    <div class="preserveHtml" id="card"></div>
    <div
      class="preserveHtml"
      id="errorMessage"
      style="margin-bottom: 10px; color: #ff5252"
    ></div>
  </div>
</template>

<script>
import Swal from 'sweetalert2';
import { api } from '../../sharedPlugin';

export default {
  props: {
    user: {
      type: Object,
      require: true
    }
  },
  data() {
    return {
      publicKey: null,
      tokenpay: null
    };
  },
  async mounted() {
    this.publicKey = await this.getPublicKey();
    this.tokenPay();
  },
  methods: {
    tokenPay() {
      if (window.TokenPay) {
        this.tokenpay = window.TokenPay(this.publicKey);
        this.tokenpay.initialize({
          dataElement: 'card',
          errorElement: 'errorMessage',
          amountElement: 'amount',
          useACH: false,
          //if displaying all 4 fields then useStyles=false, disableZip=false, disableCvv=false
          //if displaying 3 out of 4 fields then useStyles=false, and set disableZip or disableCvv equal to true
          //if displaying 2 out of 4 fields then useStyles=true, disableZip=true, disableCvv=true
          useStyles: true,
          disableZip: false,
          disableCvv: false
        });
      }
    },

    createToken() {
      return new Promise((resolve, reject) => {
        this.tokenpay.createToken(
          (result) => {
            resolve(result.token);
          },
          (error) => {
            console.error('error: ' + JSON.stringify(error));
            reject(error);
          }
        );
      });
    },

    submit() {
      this.createToken()
        .then((result) => {
          this.$emit('onToken', { token: result });
        })
        .catch((error) => {
          console.log('error', error);
          Swal.fire({
            title: 'Payment failed!',
            text:
              error && error.errorMessage
                ? error.errorMessage
                : 'Payment failed!',
            icon: 'error',
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'ok'
          });
        })
        .finally(() => this.$emit('onToken', { token: null }));
    },

    async getPublicKey() {
      const params = {
        clientId: this.user.clinicId
      };

      return await api.RectangleHealth.getTokenPayPublicKey(params)
        .then((response) => {
          return response.publicKey;
        })
        .catch((error) => {
          console.log(error);
          this.$emit('onToken', { token: null });
        });
    }
  }
};
</script>

<style scoped>
#card {
  background: #ffffffa3;
  height: auto;
  /* padding: 10px 12px; */
  /* border-radius: 5px; */
  /* border: 1px solid #84a6ae; */
  box-sizing: border-box;
  line-height: 0;
  margin: auto;
  width: auto;
}
</style>
